<template>
    <MdTable
        :columns="columns"
        :data-source="state.data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
            showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
            total: state.meta.total,
            pageSize: state.meta.per_page,
            current: state.meta.page,
        }"
        @change="handleTableChange"
        :loading="state.loading">
        <template v-for="(slot, index) of Object.keys($slots)" :key="index" #[slot]>
            <slot :name="slot"></slot>
        </template>
        <template #no="{ index }">
            <span>
                {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
            </span>
        </template>
    </MdTable>
</template>

<script>
import { defineComponent, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import apiAtsalesman from '@/services/axios/atsalesman'

export default defineComponent({
    props: {
        url: {
            default: () => '',
            type: String,
        },
        data: {
            default: () => [],
            type: Array,
        },
        columns: {
            default: () => [],
            type: Array,
        },
        params: {
            default: () => ({}),
            type: Object,
        },
        paginate: {
            default: true,
            type: Boolean,
        },
        service: {
            default: () => 'default',
            type: String,
        },
        per_page_name: {
            default: () => 'per-page',
            type: String,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                page: 1,
                [props.per_page_name]: 10,
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params[props.per_page_name] = page.pageSize
            
            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.loading = true
            let request = props.service === 'atsalesman' ? apiAtsalesman : apiClient
            
            request.get(props.url, {
                    params: {
                        ...props.params,
                        ...state.params,
                    },
                })
                .then(({ data }) => {
                    if (props.paginate) {
                        const { items, _meta } = data
    
                        state.data = items
                        state.meta.page = _meta.currentPage
                        state.meta.per_page = _meta.perPage
                        state.meta.total = _meta.totalCount
                    } else {
                        state.data = data
                    }

                    emit('update:data', state.data)
                })
                .catch(async error => {
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        emit('update:errors', `Kode error ${status}, ${statusText} : ${message}`)
                    }
                })
                .finally(() => {
                    state.loading = false
                })
        }

        watch(() => props.data, () => {
            state.data = props.data
        })

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleTableChange,
        }
    },
})
</script>