import axios from 'axios';

const apiAtsalesman = axios.create({
    baseURL: process.env.VUE_APP_ATSALESMAN_URL,
    headers: {
        'access-token': process.env.VUE_APP_ATSALESMAN_TOKEN,
        'Content-Type': 'application/json',
    },
})

// RESPONSE INTERCEPTOR: Transform data response
apiAtsalesman.interceptors.response.use(
    (response) => {
        const { data } = response

        if (!data || !data.data) {
            // Jika struktur respons tidak sesuai, return default
            return response
        }

        // Dapetin list data utama secara dinamis
        const dynamicKey = Object.keys(data.data).find(key => Array.isArray(data.data[key]))

        // Jika ketemu key array (contoh: customerInformations)
        const items = dynamicKey ? data.data[dynamicKey] : []

        // Ubah meta ke format baru
        const meta = data.data.meta || {}

        const transformedResponse = {
            items: items,
            _meta: {
                totalCount: meta.total || 0,
                pageCount: meta.lastPage || 0,
                currentPage: meta.currentPage || 1,
                perPage: meta.perPage || meta.count || 10,
            },
        }

        // Return yang sudah di-wrap, biasanya langsung response.data aja buat di frontend
        return {
            ...response,
            data: transformedResponse,
        }
    },
    (error) => {
        // Optional: handle error global
        return Promise.reject(error)
    },
)

export default apiAtsalesman
